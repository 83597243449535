<template>
  <div class="spelling-detail">
    <div class="spelling-detail__header">
      <h2>
        {{
          detail.reporter_type == "individual"
        ? "Физическое лицо"
          : "Юридическое лицо"
        }}
      </h2>
    </div>
    <div class="spelling-detail__items">
      <ul>
        <li>
          <h2>
            Ф.И.О(Наименование компании):
            <span>{{ detail.full_name }}</span>
          </h2>
        </li>
        <li v-if="detail.reporter_type == 'entity'">
          <h2>
            Ф.И.О ответственного лица:
            <span>{{ detail.responsible_person }}</span>
          </h2>
        </li>
        <li>
          <h2>
            Email:
            <span>
              <a :href="'mailto:' + detail.email">{{ detail.email }}</a>
            </span>
          </h2>
        </li>
        <li>
          <h2>
            Телефон номер:
            <span><a :href="'tel:' + detail.phone">{{ detail.phone }}</a></span>
          </h2>
        </li>
        <li>
          <h2>
            Описание:
            <p>{{ detail.text }}</p>
          </h2>
        </li>
      </ul>
    </div>

    <div>
      <a-button class="spelling_btns" style="margin-top: 10px" type="primary" @click="$router.go(-1)">
        <i class="t-transition icons icon-chevron-left" />
        {{ $t("back") }}
      </a-button>
    </div>
  </div>
</template>
<script>
export default {
  name: "ReportDetail",
  data() {
    return {
      // columns,
      detail: []
    }
  },
  async mounted() {
    let res = await this.$api.get(
      `/admin/threat_report/detail/${this.$route.params.id}/`
    )
    console.log(res)
    this.detail = res.data
  }
}
</script>
<style>
.spelling-detail__header h2 {
  font-size: 30px;
  margin: 20px 0;
}

.spelling-detail__items ul li {
  padding: 10px 0;
}

.spelling-detail__items ul li span {
  padding-left: 10px;
}

.spelling_btns {
  display: flex;
  align-items: center;
}

.spelling_btns i {
  height: 15px;
  width: 15px;
}

.spelling-detail a:hover {
  color: #eb534d;
  text-decoration: underline;
}

.spelling-detail .gray {
  color: #9e9e9e;
}
</style>
