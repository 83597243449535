<template>
  <a-row class="content-fix" type="flex">
    <!-- {{ $route.params.id }} -->
    <!-- {{ langTab }} -->
    <!-- {{ form.id }} -->
    <spinner v-if="loading" />
    <a-col v-show="!loading" class="height100 flex-1">
      <!-- {{ form.content }} -->
      <a-row class="btn-row">
        <button class="tab-btn" :class="{ active: tab == 'content' }" @click="tab = 'content'">
          <a-icon type="project" />{{ $t("ingradient") }}
        </button>

        <button class="tab-btn" :class="{ active: tab == 'photo' }" @click="tab = 'photo'">
          <a-icon type="picture" />{{ $t("Img") }}
        </button>
      </a-row>
      <div v-show="tab == 'content'">
        <!-- lang tabs -->
        <a-row align="middle" class="flex-baseline" style="margin-bottom: 15px">
          <a-col :span="4" class="form_title"> {{ $t("Lang") }}: </a-col>
          <a-col :span="18">
            <!-- lang translit tabs -->
            <a-row align="middle" class="flex-baseline flex-wrap">
              <lang-tabs v-model="langTab" class="mb-1x" />
              <!-- <a-button v-if="langTab == 'oz'" @click.stop="translitAction">
                Перевести копию на
                {{ langTab == "uz" ? "кирилицу" : "латиницу" }}
              </a-button> -->
            </a-row>
          </a-col>
        </a-row>

        <!-- title -->
        <a-row :class="{ 'form-group--error': emptyTitle }" align="middle" class="flex-baseline">
          <a-col :span="4" class="form_title">{{ $t("title") }}:</a-col>
          <a-col :span="20" class="sm-mb">
            <a-input v-model="form.title[langTab]" :placeholder="$t('EnterTitle')" class="form_title-input" />
            <div v-if="isTitleNull" class="error-text">
              {{$t('EditorErrTitle2')}}
            </div>
          </a-col>
        </a-row>
        <!-- store image modal -->
        <a-modal :visible="modalVisible" :title="$t('ImageStorages')" :closable="false"
          style="max-height: 500px; max-width: 900px" width="900px">
          <imageStorage @pasteHandler="editorInstance.insertContent(arguments[0])" />
          <template slot="footer">
            <a-button key="back" @click="modalVisible = false">
              {{ $t('Close') }}
            </a-button>
          </template>
        </a-modal>

        <!-- status toggle -->
        <a-row>
          <a-col :span="4" class="form_title"> {{ $t("Status") }}: </a-col>
          <a-switch v-model="form.is_active" />
        </a-row>
        <!-- position -->
        <a-row style="margin-top: 15px" align="middle" class="flex-baseline input-form__group">
          <a-col :span="4" class="form_title">
            {{ $t("MenuPosition") }}
          </a-col>
          <a-col :span="12">
            <a-input-number v-model="form.position" placeholder="Введите позицию" />
            <div v-if="!form.position" class="error-text">Введите позицию</div>
          </a-col>
        </a-row>

        <!-- editor -->
        <a-row :class="{ 'form-group--error': emptyContent }" style="margin-top: 25px" type="flex">
          <a-col :span="4" class="form_title" style="margin-bottom: 15px">
            {{ $t("Content") }}:
          </a-col>
          <a-col v-show="langTab == 'oz'" :span="20">
            <!-- :key="langtab" -->
            <editor :value="form.description.oz" :initial-value="form.description.oz" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.description.oz = editorChangeHandler(arguments[0])" />
            <div v-if="isContentNull" class="error-text">
             {{$t('EditorErrTitle')}}.
            </div>
          </a-col>
          <a-col v-show="langTab == 'uz'" :span="20">
            <!-- :key="langtab" -->
            <editor :value="form.description.uz" :initial-value="form.description.uz" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.description.uz = editorChangeHandler(arguments[0])" />
            <div v-if="isContentNull" class="error-text">
             {{$t('EditorErrTitle')}}.
            </div>
          </a-col>
          <a-col v-show="langTab == 'qr'" :span="20">
            <!-- :key="langtab" -->
            <editor :value="form.description.qr" :initial-value="form.description.qr" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.description.qr = editorChangeHandler(arguments[0])" />
            <div v-if="isContentNull" class="error-text">
             {{$t('EditorErrTitle')}}.
            </div>
          </a-col>
          <a-col v-show="langTab == 'ru'" :span="20">
            <!-- :key="langtab" -->
            <editor :value="form.description.ru" :initial-value="form.description.ru" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.description.ru = editorChangeHandler(arguments[0])" />
            <div v-if="isContentNull" class="error-text">
             {{$t('EditorErrTitle')}}.
            </div>
          </a-col>
          <a-col v-show="langTab == 'en'" :span="20">
            <!-- :key="langtab" -->
            <editor :value="form.description.en" :initial-value="form.description.en" :init="{
              ...tinymce.default.props,
              menubar: 'file edit view insert format tools table tc help',
              setup: (editor) => {
                editor.ui.registry.addButton('spoiler-btn', {
                  icon: 'comment-add',
                  tooltip: 'Спойлер',
                  onAction: () => {
                    spoiler.modalVisibility = editor
                    spoilerHandler()
                  }
                })
                editor.ui.registry.addButton('storage-btn', {
                  icon: 'non-breaking',
                  tooltip:'Хранилище изображений',
                  onAction: () => {
                    openStoreModal()
                    editorInstance = editor
                  }
                })
              }
            }" @input="form.description.en = editorChangeHandler(arguments[0])" />
            <div v-if="isContentNull" class="error-text">
             {{$t('EditorErrTitle')}}.
            </div>
          </a-col>
        </a-row>
      </div>
      <!-- photo tab -->
      <div v-show="tab == 'photo'">
        <!-- upload photo -->
        <a-row align="middle">
          <a-col :span="3" class="form_title"> {{ $t("TablePhotos") }} </a-col>
          <a-col :span="12">
            <image-upload v-if="!imageUploading" :list="photoList" :selected-image="main_image"
              accept="image/jpeg, image/png" method-name="news" multiple upload-multiple :with-cropper="false"
              has-method @change="handleImages" @drag-update="updateFilesPosition" @set-main-image="activeImage"
              @delete="deleteImage" />
          </a-col>
        </a-row>
      </div>

      <a-row v-if="tab == 'content'" class="form-buttons__action" style="margin-top: 20px" type="flex">
        <a-col :span="4" style="padding-right: 8px" />
        <a-col>
          <a-button :disabled="pending" @click="
            $router.push({
              name: 'notification'
            })
          ">
            <a-icon type="close" />{{ $t("Cancel") }}
          </a-button>

          <a-button :loading="pending" style="margin-left: 10px" type="primary"
            @click="submitNews((noRouterBack = true))">
            <a-icon type="check" />
            {{ form.id ? $t("Save") : "Добавить" }}
          </a-button>
          <a-button :loading="pending2" style="margin-left: 10px" type="primary"
            @click="submitNews((noRouterBack = false))">
            <a-icon type="check" />
            {{ form.id ? $t("SaveAndExit") : "Добавить" }}
          </a-button>
        </a-col>
      </a-row>
    </a-col>

    <spoiler-modal v-show="!!spoiler.modalVisibility" v-model="spoiler" @ok="appendToEditor"
      @cancel="closeSpoilerModal" />
  </a-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import spoiler from "@/mixins/spoiler.js"

const newsDefaultFields = () => ({
  title: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  slug: "",
  is_active: true,
  description: {
    uz: "",
    oz: "",
    ru: "",
    en: "",
    qr: ""
  },
  attachments: [],
  position: 1
})

export default {
  name: "News",

  components: {
    "image-upload": () => import("@/components/utils/image-upload"),
    imageStorage: () => import("@/components/imageStorage/imageStorage"),
    spoilerModal: () =>
      import("@/components/directory/modal-components/modal-spoiler"),
    editor: () => import("@tinymce/tinymce-vue")
  },

  mixins: [spoiler],

  data() {
    return {
      tab: "content",
      // eslint-disable-next-line no-undef
      langTab: $langPrefix,
      imageUploading: false,
      form: { ...newsDefaultFields() },
      fileList: [],
      image_List: [],
      pending: false,
      pending2: false,
      loading: false,
      menuList: [],
      childMenuList: [],
      parent_id: null,
      child_id: null,
      main_image: null,

      newsFiles: [],
      document_file: null,
      document_files_array: [],
      tag: "",
      emptyTitle: false,
      emptyContent: false,

      modalVisible: false,
      tagList: []
    }
  },

  computed: {
    isTitleNull() {
      return !!(
        !this.form.title.uz &&
        !this.form.title.oz &&
        !this.form.title.ru &&
        !this.form.title.en
      )
    },
    isContentNull() {
      return !!(
        !this.form.description.uz &&
        !this.form.description.oz &&
        !this.form.description.ru &&
        !this.form.description.en
      )
    },
    photoList() {
      return this.form && this.form.attachments
    },
    getKey() {
      return this.langTab
    }
  },

  watch: {
    $route: {
      handler() {
        this.fetchData()
      },
      immediate: true
    },
    parent_id(newVal) {
      this.findChild(newVal)
    }
  },

  async created() {
    this.loading = true

    this.form = {
      ...newsDefaultFields()
    }
    this.loading = false
  },

  methods: {
    async deleteImage(val) {
      await this.$api.delete(
        "/admin/notification/attachment/" + val + "/delete/"
      )
    },
    openStoreModal() {
      this.modalVisible = true
    },
    translitAction() {
      try {
        const from = this.langTab,
          to = this.langTab == "uz" ? "oz" : "uz",
          methodName = this.langTab == "uz" ? "toKirill" : "toLatin"

        if (this.form.title[from]) {
          this.form.title[to] = this[methodName](this.form.title[from])
        }

        if (this.form.description[from]) {
          this.form.description[to] = this[methodName](
            this.form.description[from]
          )
        }
      } catch (error) {
        this.$sentry.captureMessage(error)
      }
    },
    updateFilesPosition(files) {
      console.log(files)
    },
    check2Null(arg) {
      return Object.values(arg).every((item) => !item)
    },
    validateForm() {
      if (this.check2Null(this.form.title)) {
        this.emptyTitle = true
        this.$message.error("Введите заголовок на одном из языков")
        return false
      }
      if (this.check2Null(this.form.description)) {
        this.emptyContent = true
        this.$message.error("Введите контент на одном из языков")
        return false
      }

      return true
    },
    async submitNews(noRouterBack) {
      if (!this.validateForm()) return

      if (this.pending) return

      if (noRouterBack == true) {
        this.pending = true
      } else {
        this.pending2 = true
      }

      try {
        let newData = cloneDeep(this.form)
        delete newData.attachments

        newData = {
          ...newData,
          description_ru: newData.description.ru,
          description_uz: newData.description.uz,
          description_oz: newData.description.oz,
          description_en: newData.description.en,
          title_ru: newData.title.ru,
          title_uz: newData.title.uz,
          title_oz: newData.title.oz,
          title_en: newData.title.en
        }

        delete newData.description
        delete newData.slug
        delete newData.title

        let f = new FormData()
        Object.keys(newData).forEach((key) => {
          newData[key] && key != "tags" && f.append(key, newData[key])
          key == "is_active" && f.append(key, newData[key])
        })
        const createOrUpdate = {
          url:
            this.form && this.form.id
              ? "/admin/notification/" + this.form.id + "/update/"
              : "/admin/notification/create/",
          method: this.form && this.form.id ? "patch" : "post",
          data: f
        }
        if (
          !(await this.$api[createOrUpdate.method](
            createOrUpdate.url,
            createOrUpdate.data,
            { timeout: 0 }
          ).then((res) => {
            if (res && res.status >= 200 && res.data && res.data.id) {
              if (res.data.id) {
                const { id } = res.data
                this.form = { ...this.form, id }
                return true
              }
            }
          }))
        ) {
          if (noRouterBack == true) {
            this.pending = false
          } else {
            this.pending2 = false
          }
          return
        }

        // upload after post done
        this.uploadPostFiles(noRouterBack)
      } catch (error) {
        console.error(error)
        this.$message.error(
          "Возникла проблема при " +
          (this.form && this.form.id ? "сохранении" : "добавлении") +
          " новости" +
          "\r\n\r\n" +
          error
        )

        this.$sentry.captureMessage(error)
      }
    },
    async uploadPostFiles(noRouterBack = true) {
      this.pending = true

      try {
        this.pending = false
        this.$message.success("Новость успешно сохранено")

        if (!noRouterBack) {
          this.$router.push({
            name: "notification"
          })
        }
      } catch (error) {
        this.pending = false
        this.$sentry.captureMessage(error)
      }
    },

    fetchData() {
      this.loading = true

      if (isNaN(this.$route.params.id)) {
        this.loading = false
        return
      }

      this.$api
        .get("/admin/notification/" + this.$route.params.id + "/detail/")
        .then(async (res) => {
          if (res && res.data) {
            const { ...data } = res.data
            this.form = {
              ...this.form,
              ...data,
              title: {
                ru: res.data.title_ru,
                qr: res.data.title_qr,
                en: res.data.title_en,
                oz: res.data.title_oz,
                uz: res.data.title_uz
              },
              description: {
                ru: res.data.description_ru,
                en: res.data.description_en,
                oz: res.data.description_oz,
                uz: res.data.description_uz,
                qr: res.data.description_qr
              }
            }
          }
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },

    async handleImages(arg) {
      this.imageUploading = true
      this.fileList = arg
      this.image_List = arg
      let formImage = {
        notification: this.$route.params.id
      }
      let newImages = arg.filter((item) => item && !item.id)
      let f = new FormData()
      Object.keys(formImage).forEach((k) => {
        f.append(k, formImage[k])
      })
      newImages.forEach((item) => {
        f.append(
          "photo",
          item && item.fileTarget,
          item && item.fileTarget && item.fileTarget.name
        )
      })
      let res = await this.$api.post(
        "/admin/notification/attachment/create/",
        f
      )
      if (res && res.data) {
        this.form.images = res.data.images
        this.imageUploading = false
      }
    },
    activeImage(e) {
      this.photoList.forEach((item, index) => {
        this.photoList[index].is_active = item.id == e
      })
      this.$api.patch("/admin/notification/attachment/" + e + "/update/")
    }
  }
}
</script>
<style>
.ant-modal {
  max-height: 600px;
}

.scroll-container {
  padding: 20px 0px;
}

input[type="datetime-local"] {
  border-radius: 2px;
  padding: 4px;
  border: 1px solid #c7c7c7;
}

.flex-end {
  justify-content: flex-end;
}
</style>
